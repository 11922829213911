<template>
    <v-container fluid>

        <v-row>
            <v-col cols="6">
                <v-row>
                    <v-col cols="4">
                        <v-subheader class="d-flex justify-end">Nombre de Empresa</v-subheader>
                    </v-col>
                    <v-col cols="8">
                        <v-text-field label="" v-model="user.company_name" required outlined dense></v-text-field>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="6">
                <v-row>
                    <v-col cols="4">
                        <v-subheader class="d-flex justify-end">Nombre</v-subheader>
                    </v-col>
                    <v-col cols="8">
                        <v-text-field label="" v-model="user.name" required outlined dense></v-text-field>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="6">
                <v-row>
                    <v-col cols="4">
                        <v-subheader class="d-flex justify-end">Apellido</v-subheader>
                    </v-col>
                    <v-col cols="8">
                        <v-text-field label="" v-model="user.last_name" required outlined dense></v-text-field>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>


        <v-row>
            <v-col cols="6">
                <v-row>
                    <v-col cols="4">
                        <v-subheader class="d-flex justify-end">N°Documento/ CE </v-subheader>
                    </v-col>
                    <v-col cols="8">
                        <v-text-field label="" v-model="user.document_number" required outlined dense></v-text-field>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="6">
                <v-row>
                    <v-col cols="4">
                        <v-subheader class="d-flex justify-end">Alias </v-subheader>
                    </v-col>
                    <v-col cols="8">
                        <v-text-field label="" v-model="user.alias" required outlined dense></v-text-field>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="6">
                <v-row>
                    <v-col cols="4">
                        <v-subheader class="d-flex justify-end"> Télefono </v-subheader>
                    </v-col>
                    <v-col cols="8">
                        <v-text-field label="" v-model="user.phone" required outlined dense></v-text-field>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="6">
                <v-row>
                    <v-col cols="4">
                        <v-subheader class="d-flex justify-end"> Correo </v-subheader>
                    </v-col>
                    <v-col cols="8">
                        <v-text-field label="" v-model="user.email" required outlined dense></v-text-field>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>

// import { mapState, mapActions } from "vuex";
import { required } from "vuelidate/lib/validators";
import mixins from "@/mixins";

export default {
    name: "form-company",
    props: ["user"],
    // components: { InputPhotoUpload },
    watch: {},
    validations() {
        return {
            user: {
                // title: required,
                // fifa_position: required,
                // continent_id: required,
                // games_won: required,
                // games_lost: required,
                // games_tied: required,
                // goals: required,
                // players: required,
            },
        };
    },
    data: () => ({
    }),
    computed: {
        // ...mapState({
        //     selectTeams: (state) => state.team.selectTeams,
        // }),
    },
    methods: {
        // ...mapActions(["fetchAllTeams"]),
        getFetch() {
            // this.fetchAllTeams();
        },
    },
    created() {
        this.getFetch();
    },
};
</script>

<style scoped>
/* .row {
    margin-top: -10px !important;
} */
</style>