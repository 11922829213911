<template>
    <v-container fluid>
      <v-col col="12" md="12" lg="12" outlined>
        <v-card class="pa-4 rounded-xl">
          <v-card-title class="justify-left d-flex flex-row pb-1">
            <p>Empresa</p>
            <v-spacer></v-spacer>
          </v-card-title>
          <v-divider color="#75D89F" class="mx-3"></v-divider>
          <v-card-text>
            <!-- <h3 style="color:#083C5F" class="pb-4">Información del Grupo</h3> -->
            <v-row>
              <v-col col="12">
                <form-company :user="iuser"></form-company>
              </v-col>
            </v-row>
            <v-row class="d-flex justify-end">
              <v-btn @click="save()" class="ma-3 rounded-lg text-capitalize" dense color="primary">
                Grabar
              </v-btn>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-snackbar v-model="snackbar" :color="color" class="mr-2" top>
        <span class="d-flex justify-center white--text">{{ text }}</span>
      </v-snackbar>
    </v-container>
  </template>
  
  
  
  <script>
  import FormCompany from "@/components/forms/FormCompany.vue";
  import { mapState, mapActions, mapGetters } from "vuex";
  import _cloneDeep from 'lodash/cloneDeep';
  
  export default {
    name: "CompanyAddEdit",
    props: ["id"],
    components: {
        FormCompany,
    },
    watch: {
      user: function (value) {
        // console.log(value)
        if (this.id) {
          this.iuser = _cloneDeep(value);
        } 
      },
      userError: function (value) {
        if (value)
          this.mensaje(true, "error", this.userError.message, 5000);
      },
      userSusccesMessage: function (value) {
        if (value) {
          this.mensaje(true, "success", this.userSusccesMessage, 5000);
          if (!this.id) this.iuser = {};
          this.$router.go(-1);
        }
      },
  
    },
    computed: {
      ...mapState({
        user: (state) => state.user.user,
        userError: (state) => state.user.userError,
        userSusccesMessage: (state) => state.user.userSusccesMessage,
      }),
    },
    data: () => ({
      iuser: {
      },
      snackbar: false,
      color: "",
      text: "",
    }),
    methods: {
      ...mapActions(["addCompany", "editCompany", "getCompanyById"]),
      save() {
        // console.log(this.iuser);
        if (this.id) {
          //update
          this.editCompany({ company: this.iuser });
        } else {
          this.addCompany({ company: this.iuser });
          //create
        }
      },
      mensaje(snackbar, color, text) {
        this.snackbar = snackbar;
        this.color = color;
        this.text = text;
      },
      getFetch() {
        // console.log(this.user)
        if (this.id) {
          this.getCompanyById({ id: this.id });
        }
      },
    },
    created() {
      this.getFetch();
    },
  };
  </script>
  
  <style scoped>
  </style>